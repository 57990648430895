<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup fullscreen title="Nouveau fournisseur" :active.sync="popupNouveauFournisseur">
        <!-- Content Row -->
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Infos générales">
              <div class="vx-row">
                <div class="vx-col  w-full">
                  <vs-input class="w-full mt-4" label="Fournisseur" v-model="Fournisseur" name="Fournisseur" />

                  <vs-input class="w-full mt-4" label="Site" v-model="SiteCommande" type="text"  name="SiteCommande" />
                </div>
              </div>

              <vs-textarea class="w-full mt-4" counter="250" label="Remarque" :counter-danger.sync="counterDanger" v-model="Remarque" />

              <div class="demo-alignment">
                <span>Espèce:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiEspece"></vs-checkbox>
                </div>

                <span>Chèque:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiCheq"></vs-checkbox>
                </div>

                <span>Virement:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiVirement"></vs-checkbox>
                </div>

                <span>Mobile Money:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiMobileMoney"></vs-checkbox>
                </div>
              </div>

              <!-- Gender -->

            </vx-card>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Adresses">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">
                <vs-input class="w-full mt-4" label="Adresse" v-model="Adresse" name="Adresse" />

                <vs-input class="w-full mt-4" label="Telephone" v-model="Tel" name="Telephone" />

                <vs-select autocomplete v-model="Pays" class="w-full select-large mt-4" label="Pays">
                  <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in PaysData" class="w-full" />
                </vs-select>

                <vs-select autocomplete v-model="Quartier" class="w-full select-large mt-4" label="Quartier">
                  <vs-select-item :key="index" :value="item.libelleQtier" :text="item.libelleQtier" v-for="(item,index) in QuartierData" class="w-full" />
                </vs-select>


              </div>
              <div class="vx-col md:w-1/2 w-full">
                <vs-input class="w-full mt-4" label="Email" v-model="Email" name="Email" />

                <vs-select autocomplete v-model="Ville" class="w-full select-large mt-4" label="Ville">
                  <vs-select-item :key="index" :value="item.libelleVille" :text="item.libelleVille" v-for="(item,index) in VillesData" class="w-full" />
                </vs-select>

                <vs-input class="w-full mt-4" label="Localité" v-model="Localite" type="text"  name="Localite" />
                <vs-input class="w-full mt-4" label="Fax" v-model="Fax" type="text"  name="Fax" />

              </div>
            </div>


            </vx-card>


          </div>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-8 flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="saveFournisseurVerre()">ENREGISTRER</vs-button>
              <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data()">ANNULER</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup fullscreen title="Modifier un fournisseur"  :active.sync="popupUpdateFournisseur">
        <!-- Content Row -->
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Infos générales">
              <div class="vx-row">
                <div class="vx-col  w-full">
                  <vs-input class="w-full mt-4" label="Fournisseur" v-model="Fournisseur" name="Fournisseur" />

                  <vs-input class="w-full mt-4" label="Site" v-model="SiteCommande" type="text"  name="SiteCommande" />
                </div>
              </div>

              <vs-textarea class="w-full mt-4" counter="250" label="Remarque" :counter-danger.sync="counterDanger" v-model="Remarque" />

              <div class="demo-alignment">
                <span>Espèce:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiEspece"></vs-checkbox>
                </div>

                <span>Chèque:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiCheq"></vs-checkbox>
                </div>

                <span>Virement:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiVirement"></vs-checkbox>
                </div>

                <span>Mobile Money:</span>
                <div class="flex">
                  <vs-checkbox v-model="PaiMobileMoney"></vs-checkbox>
                </div>
              </div>

              <!-- Gender -->

            </vx-card>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Adresses">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">
                <vs-input class="w-full mt-4" label="Adresse" v-model="Adresse" name="Adresse" />

                <vs-input class="w-full mt-4" label="Telephone" v-model="Tel" name="Telephone" />

                <vs-select autocomplete v-model="Pays" class="w-full select-large mt-4" label="Pays">
                  <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in PaysData" class="w-full" />
                </vs-select>

                <vs-select autocomplete v-model="Quartier" class="w-full select-large mt-4" label="Quartier">
                  <vs-select-item :key="index" :value="item.libelleQtier" :text="item.libelleQtier" v-for="(item,index) in QuartierData" class="w-full" />
                </vs-select>


              </div>
              <div class="vx-col md:w-1/2 w-full">
                <vs-input class="w-full mt-4" label="Email" v-model="Email" name="Email" />

                <vs-select autocomplete v-model="Ville" class="w-full select-large mt-4" label="Ville">
                  <vs-select-item :key="index" :value="item.libelleVille" :text="item.libelleVille" v-for="(item,index) in VillesData" class="w-full" />
                </vs-select>

                <vs-input class="w-full mt-4" label="Localité" v-model="Localite" type="text"  name="Localite" />
                <vs-input class="w-full mt-4" label="Fax" v-model="Fax" type="text"  name="Fax" />

              </div>
            </div>


            </vx-card>


          </div>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-8 flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="updateFournisseurVerre()">MODIFIER</vs-button>
              <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data()">ANNULER</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">

            <vs-table multiple stripe noDataText="Aucune donnée" v-model="selected" pagination max-items="50" search :data="fournisseur_verres_data">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 mr-5 md:mb-0" @click.stop="popupNouveauFournisseur=true">Nouveau fournisseur</vs-button>
              </template>
              <template slot="thead">
                <vs-th>
                  N°
                </vs-th>
                <vs-th sort-key="Fournisseur">
                  Fournisseur
                </vs-th>
                <vs-th sort-key="Tel">
                  Tel
                </vs-th>
                <vs-th sort-key="Email">
                  Email
                </vs-th>
                <vs-th sort-key="Adresse">
                  Adresse
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td>
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="tr.Fournisseur">
                    {{tr.Fournisseur}}
                  </vs-td>

                  <vs-td :data="tr.Tel">
                    {{tr.Tel}}
                  </vs-td>

                  <vs-td :data="tr.Email">
                    {{tr.Email}}
                  </vs-td>

                  <vs-td :data="tr.Adresse">
                    {{tr.Adresse}}
                  </vs-td>

                  <vs-td>
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateFournisseurVerreShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupNouveauFournisseur: false,
      popupUpdateFournisseur: false,
      selected:[],
      clientId: '',
      clients: [],
      currentClientDeletedId: '',
      idOfficine: null,
      IdUser: null,
      activeUserInfos: null,

      IdFournisseurVerre: null,

      Fournisseur: '',
      Tel: '',
      SiteCommande: '',
      Email: '',
      Adresse: '',
      Pays: '',
      Ville: '',
      Quartier: '',
      Localite: '',
      Fax: '',
      Remarque: '',
      PaiEspece: false,
      PaiCheq: false,
      PaiVirement: false,
      PaiMobileMoney: false,

      counterDanger: false
    }
  },
  computed: {
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    },
    officines () {
      return this.$store.state.officine.officines
    },
    PaysData () {
      return this.$store.state.pays.pays
    },
    VillesData () {
      return this.$store.state.pays.villes
    },
    QuartierData () {
      return this.$store.state.pays.quartiers
    }
  },

  methods: {
    updateFournisseurVerreShowForm (data) {
      const fournisseur = this.$store.getters['verre/getFournisseurVerreById'](data._id)

      this.IdFournisseurVerre = data._id

      this.Fournisseur = fournisseur.Fournisseur
      this.Tel = fournisseur.Tel
      this.SiteCommande = fournisseur.SiteCommande
      this.Email = fournisseur.Email
      this.Adresse = fournisseur.Adresse
      this.Pays = fournisseur.Pays
      this.Ville = fournisseur.Ville
      this.Quartier = fournisseur.Quartier
      this.Localite = fournisseur.Localite
      this.Fax = fournisseur.Fax
      this.Remarque = fournisseur.Remarque
      this.PaiEspece = fournisseur.PaiEspece
      this.PaiCheq = fournisseur.PaiCheq
      this.PaiVirement = fournisseur.PaiVirement
      this.PaiMobileMoney = fournisseur.PaiMobileMoney

      this.popupUpdateFournisseur = true
    },
    updateFournisseurVerre () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Fournisseur: this.Fournisseur,
        Tel: this.Tel,
        SiteCommande: this.SiteCommande,
        Email: this.Email,
        Adresse: this.Adresse,
        Pays: this.Pays,
        Ville: this.Ville,
        Quartier: this.Quartier,
        Localite: this.Localite,
        Fax: this.Fax,
        Remarque: this.Remarque,
        PaiEspece: this.PaiEspece,
        PaiCheq: this.PaiCheq,
        PaiVirement: this.PaiVirement,
        PaiMobileMoney: this.PaiMobileMoney,
        id: this.IdFournisseurVerre
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateFournisseurVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()
          this.popupUpdateFournisseur = false
          this.IdFournisseurVerre = null
          this.reset_data()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    ClientDetailShowForm (data) {
      this.$router.push(`/apps/client/client-view/${data._id}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (data) {
      this.IdFournisseurVerre = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le fournisseur  "${data.Fournisseur}`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/RemoveFournisseurVerre', this.IdFournisseurVerre)
        .then(() => {
          this.$vs.loading.close()
          this.showDeleteSuccess()
          this.IdFournisseurVerre = null
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Fournisseur verre supprimé',
        text: 'Le fournisseur selectionné a bien été supprimée'
      })
    },
    saveFournisseurVerre () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Fournisseur: this.Fournisseur,
        Tel: this.Tel,
        SiteCommande: this.SiteCommande,
        Email: this.Email,
        Adresse: this.Adresse,
        Pays: this.Pays,
        Ville: this.Ville,
        Quartier: this.Quartier,
        Localite: this.Localite,
        Fax: this.Fax,
        Remarque: this.Remarque,
        PaiEspece: this.PaiEspece,
        PaiCheq: this.PaiCheq,
        PaiVirement: this.PaiVirement,
        PaiMobileMoney: this.PaiMobileMoney
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/addFournisseurVerre', payload)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.popupNouveauFournisseur = false
          this.reset_data()
        })
        .catch((error) => {
          this.$vs.loading.close()
          console.log(error)
        })
    },

    reset_data () {
      this.Fournisseur = ''
      this.Tel = ''
      this.SiteCommande = ''
      this.Email = ''
      this.Adresse = ''
      this.Pays = ''
      this.Ville = ''
      this.Quartier = ''
      this.Localite = ''
      this.Fax = ''
      this.Remarque = ''
      this.PaiEspece = false
      this.PaiCheq = false
      this.PaiVirement = false
      this.PaiMobileMoney = false
    },

    getFournisseursVerres () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getFournisseursVerres')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },


    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },

    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdUser = this.activeUserInfos._id
      this.idOfficine = this.activeUserInfos.IdOfficine
    },

    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .then(() => { })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getFournisseursVerres()
    this.activeUserInfo()
    this.getPays()
    this.getVilles()
    this.getQuartiers()
    this.getOfficines()
  }
}
</script>

